export const setTlosPrice = (state, tlosPrice) => {
    state.tlosPrice = tlosPrice;
};

export const setGasPrice = (state, gasPrice) => {
    state.gasPrice = gasPrice;
};

export const setLatestBlock = (state, latestBlock) => {
    state.latestBlock = latestBlock;
};
