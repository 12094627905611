export default {
    locale: {
        current_language_name: 'Deutsch',
    },
    pages: {
        blocklist: {
            title: 'Blöcke',
            display_empty_blocks: 'Leere Blöcke anzeigen',
        },
        blockpage: {
            block: 'Block',
            overview: 'Übersicht',
            transactions: 'Transaktionen',
        },
        transactions: {
            contract_deployment: 'Vertragsbereitstellung',
            deposit_action_name: 'Einzahlung',
            deposit_native: 'Einzahlung (nativ)',
            five_hundred_k_disclaimer: 'Anzeige der letzten 500.000 Datensätze von { total }',
            for_block: 'für Block',
            for: 'für',
            gas_info_label: 'Gas-Info',
            native_deposit_tooltip: 'Einzahlung von Native Telos',
            native_withdraw_tooltip: 'Auszahlung zu Native Telos',
            nonce_label: 'Nonce',
            see_more_details: 'Weitere Details anzeigen',
            see_tx_preview_tooltip: 'Vorschau der Transaktionsdetails anzeigen',
            status_label: 'Status',
            to: 'an',
            transaction_action_label: 'Transaktionsaktion',
            transaction_fee_label: 'Transaktionsgebühr',
            transactions: 'Transaktionen',
            transfer_for_x_tlos_from: 'Überweisung von { amount } TLOS von',
            transfer_tlos_action_name: 'TLOS überweisen',
            withdraw_action_name: 'Auszahlung',
            withdraw_native: 'Auszahlung (nativ)',
            x_gas_used_of_y_limit: '{ amount } Gas verwendet von { limit } Limit',
        },
        transaction: {
            page_title: 'Details der Transaktion',
            overview: 'Übersicht',
            logs: 'Protokolle',
            internal: 'Interne Transaktionen',
            not_found: 'Transaktion nicht gefunden',
        },
        internaltrx: {
            page_title: 'Interne Transaktionen des Vertrags',
            for_address: 'für die Adresse { address }',
        },
        home: {
            telos_evm_explorer: 'Der Telos EVM Explorer',
            market_cap: 'Marktkapitalisierung',
            last_finalized_block: 'Letzter finalisierter Block',
            total_transactions: 'Gesamtanzahl der Transaktionen',
            latest_blocks: 'Neueste Blöcke',
            view_all_blocks: 'Alle Blöcke anzeigen',
            latest_transactions: 'Neueste Transaktionen',
            view_all_transactions: 'Alle Transaktionen anzeigen',
        },
        error: {
            title: 'Seite nicht gefunden.',
            subtitle: 'Bitte überprüfen Sie die URL erneut. Wenn sie korrekt aussieht, versuchen Sie es später noch einmal.',
        },
        issuer: 'Issuer',
        account_not_found: 'Wir konnten dieses Konto nicht finden',
        approvals: 'Approvals',
        supported_interfaces: 'Bekannte unterstützte Schnittstellen',
        explore_transactions: 'Transaktionen prüfen',
        recent_transactions: 'Neueste Transaktionen',
        rpc_endpoints: 'RPC Endpunkte',
        monitor: 'Monitor',
        go_home: 'Home',
        minted: 'Minted',
        holders: 'Holders',
        evm_holders: 'Alle Telos EVM Halter',
        total_nfts_minted: 'Insgesamt geprägte NFTs in dieser Sammlung',
        telos_supply: 'Telos EVM supply',
        select_sol_file: 'Bitte .sol Smart Contract Datei für Hochladen auswählen',
        select_json_file: 'Bitte standard JSON Objekt-Datei für Hochladen auswählen',
        paste_contract_contents: 'Sie müssen eine Datei zum Hochladen auswählen oder den Reiter wechseln, um Smart Contract Inhalte direkt einzufügen.',
        contract_address: 'Smart Contract Adresse',
        enter_contract_address: 'Bitte Smart Contract Adresse eingeben \'0x0123...\'',
        invalid_address_format: 'Ungültiges Adress-Format',
        compiler_version: 'Compiler Version',
        select_compiler_version: 'Compiler Version auswählen',
        eg_contracts: 'd.h.:, \'contracts/\'',
        contract_file_directory_path: 'Smart Contract Datei(en) Verzeichnis Pfad (leer lassen, falls nicht vorhanden)',
        invalid_path_format: 'Der Pfad muss mit einem Schrägstrich enden /',
        upload_file: 'Datei hochladen',
        text_input: 'Texteingabe',
        runs_value_for_optimization: 'Führt Wert zur Optimierung aus',
        constructor_arguments: 'Constructor Arguments',
        comma_seperated_values: 'Kommagetrennte Werte z.B.:, Bob,123,0x12345...',
        no_trailing_commas: 'Keine nachgestellten Kommas',
        paste_contract_code_here: 'Smart Contract Code kopieren und hier einfügen...',
        enter_contract_text: 'Smart Contract Code eingeben oder einfügen',
        verify_contract: 'Smart Contract verifizieren',
        reset: 'Zurücksetzen',
        gas_used: 'Verbrauchtes Gas',
        account: 'Konto',
        contract: 'Smart Contract',
        transfers_title: '{ type } Transfers',
        loading_transfers: 'Loading transfers',
        loading_approvals: 'Loading approvals',
        erc20_transfers: 'ERC20 Transfers',
        erc20_transfers_tooltip: 'Liste der ERC20 Token-Übertragungen in dieser Transaktion',
        erc721_transfers: 'ERC721 Transfers',
        erc721_transfers_tooltip: 'Liste der ERC721 Token-Übertragungen in dieser Transaktion',
        erc1155_transfers: 'ERC1155 Transfers',
        erc1155_transfers_tooltip: 'Liste der ERC1155 Token-Übertragungen in dieser Transaktion',
        tokens: 'Tokens',
        created_at_trx: 'Erstellt in Transaktion',
        by_address: 'Durch Konto/Adresse',
        number_used_once: 'Einmalig verwendete Nummer (nonce)',
        native_account: 'Konto: Telos Native',
        balance: 'Saldo',
        view_source_prompt: 'Dieser Smart Contract wurde überprüft. Sie können den Quellcode und die Metadaten im Reiter \'contract\' ansehen',
        account_url: '{ domain }/account/{ account }',
        tlos_balance: '{ balance } TLOS',
        couldnt_retreive_metadata_for_address: 'Metadata konnten nicht abgerufen werden { address }: { message }',
        transaction_details: 'Transaktions-Details',
        transaction_not_found: 'Nicht gefunden: { hash }',
        general: 'Allgemein',
        details: 'Details',
        logs: 'Protokolle',
        internal_txns: 'Interne Transaktionen',
        transaction_hash: 'Transaktions Hash',
        click_to_change_date_format: 'Klicken Sie hier, um das Datumsformat zu ändern',
        block_number: 'Block Nummer',
        from: 'Von',
        to: 'Nach',
        date: 'Datum',
        success: 'Erfolg',
        failure: 'Fehlgeschlagen',
        status: 'Status',
        balance_gwei: '{ amount } GWEI',
        balance_tlos: '{ amount } TLOS',
        error_message: 'Fehlermeldung',
        contract_function: 'Smart Contract Funktionen',
        function_parameters: 'Funktions-Parameter',
        deployed_contract: 'Bereitgestellter Smart Contract',
        click_to_show_in_wei: 'Anklicken zum Einblenden in wei',
        gas_price_charged: 'Berechnete Transaktionskosten',
        gas_fee: 'Transaktionskosten',
        gas_limit: 'Transaktionskosten-Limit',
        nonce: 'Nonce',
        input: 'Eingabe',
        output: 'Ausgabe',
        value: 'Wert',
        count: 'Anzahl',
        overview: 'Übersicht',
        more_info: 'mehr Infos',
        transaction_sent: 'txn gesendet',
        first: 'erste',
        last: 'zuletzt',
        contract_creator: 'Vertragsersteller',
    },
    components: {
        gas_limit_tooltip: 'Die maximale Menge an Gas, die für diese Transaktion ausgegeben werden kann',
        gas_used_tooltip: 'Die Menge an Gas, die bei dieser Transaktion verbraucht wurde',
        known_tokens: 'Known tokens',
        other_tokens: 'Unknown tokens',
        no_balances_found: 'Für diese Adresse wurde kein erc20-Token-Guthaben gefunden',
        internal_txns: 'Interne Transaktionen',
        n_internal_txns: '{ amount } Interne Transaktionen',
        none: 'Keine',
        verify_prompt: 'Dieser Smart Contract wurde noch nicht überprüft.  Möchten Sie den Contract/die Contracts und deren Metadaten jetzt hochladen, um die Quelle zu verifizieren?',
        verify_contract: 'Smart Contract verifizieren',
        search_evm_address_failed: 'Suche nach EVM-Adresse zu Telos Native Konto { accountName } fehlgeschlagen. Sie können auf wallet.telos.net eine EVM-Adresse erstellen.',
        unknown_web3_login_type: 'Unbekannter web3 Login Typ: { provider }',
        unknown_evm_login_provider: 'Anbieter der EVM-Anmeldung deaktiviert: { provider }',
        unknown_native_login_provider: 'Anbieter der Native-Anmeldung deaktiviert: { provider }',
        connect_wallet: 'Wallet verbinden',
        view_address: 'Adresse ansehen',
        disconnect: 'Verbindung trennen',
        disable_wallet_extensions: 'Deaktivieren Sie die Wallet-Erweiterungen oder setzen Sie Brave-Wallet als Standard in den Browser-Einstellungen, um Brave Wallet zu verwenden.',
        enable_wallet_extensions: 'Aktivieren Sie die MetaMask-Erweiterung und setzen Sie die Voreinstellung "Erweiterung bevorzugen" in den Browser-Einstellungen, um die MetaMask-Wallet zu verwenden.',
        evm_wallets: 'EVM-Wallets',
        advanced: 'Erweiterte Optionen',
        continue_on_metamask: 'Mit Metamask fortfahren',
        text1_native_wallets: 'Telos Native Wallets für',
        text2_advanced_users: 'Fortgeschrittene Benutzer',
        text3_or_to_recover_assets: 'oder zur Wiedererlangung von Werten, die an eine Telos Native Adresse gesendet wurden',
        copy_to_clipboard: '{ text } in die Zwischenablage kopieren',
        latest_block: 'Letzter Block',
        price_sources: 'Daten von Coingecko, CoinMarketCap oder, falls nicht verfügbar, von dezentralen Börsen auf Telos EVM',
        marketcap_sources: 'Daten stammen von Coingecko oder CoinMarketCap',
        usd_marketcap: 'USD Marketcap',
        usd_price: 'USD Preis',
        usd_value: 'USD Wert',
        tlos_price: 'TLOS Preis',
        gas_price: 'Gas Preis',
        gas_price_tlos: '(Gas Price * Gas Used) in TLOS',
        gas_price_gwei: 'Gas Price in gwei',
        click_to_expand: 'Zum erweitern klicken',
        click_to_fold: 'Zum Falten klicken',
        expand_all: 'Alle erweitern',
        collapse_all: 'Alle einklappen',
        search_evm_failed: 'Suche nach verlinkter EVM-Adresse für Telos Native Konto { search_term } fehlgeschlagen.',
        search_failed: 'Suche fehlgeschlagen, bitte geben Sie einen gültigen Suchbegriff ein.',
        add_to_metamask: '{ symbol } zu MetaMask hinzufügen',
        tx_hash: 'Txn Hash',
        direction: 'Richtung',
        block: 'Block',
        date: 'Date Time (UTC)',
        age: 'Age',
        method: 'Methode',
        to_interacted_with: 'An',
        value: 'Wert',
        txn_fee: 'Txn Fee',
        txn_failed: 'Txn Gescheitert',
        token: 'Token',
        unknown_precision: 'Unbekannte Präzision/Dezimalstellen',
        click_to_change_format: 'Zum Ändern des Formats anklicken',
        balance: 'Saldo',
        error_fetching_balance: 'Fehler beim Aufrufen des Saldos',
        launch_metamask_dialog_to_add: 'MetaMask Dialog öffnen, um { symbol } hinzuzufügen',
        search_hints: 'Transaktion,Adresse/Konto,Block',
        no_provider_found: 'Mehr als ein Anbieter ist aktiv, bitte deaktivieren Sie zusätzliche Anbieter oder der aktuelle EVM-Wallet-Anbieter wird nicht unterstützt.',
        copied: 'Kopiert',
        copy_to_clipboard_failed: 'Koipieren in die Zwischenablage ist fehlgeschlagen',
        gwei: 'Gwei',
        failed_to_fetch_transactions: 'Transaktionen konnten nicht abgerufen werden.',
        failed_to_parse_transaction: 'Das Parsen der Daten für die Transaktion ist fehlgeschlagen, Fehler: { message }',
        executed_based_on_decoded_data: 'Name of the function executed based. For unidentified functions, method ID is displayed instead.',
        unsupported_token_type: 'Token Typ nicht unterstützt: { tokenType }',
        token_id: 'Id #{ tokenId }',
        status: 'Status',
        nonce: 'Nonce',
        from: 'Von',
        to: 'An',
        item: 'Element',
        amount: 'Menge',
        download_image: 'Bild herunterladen',
        confirm_download_image: 'Bestätigen Sie, dass Sie dieses Bild herunterladen möchten',
        confirm: 'Bestätigen',
        click_to_toggle_media_size: 'Klicken, um die Mediengröße umzuschalten',
        toggle_expand: 'Passform auf Bildschirm oder Originalgröße umschalten',
        close: 'Schließen',
        input_viewer: {
            name: 'Name',
            type: 'Typ',
            data: 'Daten',
            default_view: 'Standardansicht',
            original_view: 'Originalansicht',
            decoded_view: 'Dekodierte Ansicht',
            copy_button_desc: 'Parametertabelle',
        },
        approvals: {
            token_id: 'Token ID',
            approved: 'Genehmigt',
            approvals_granted_title: 'ERC-Genehmigungen:',
            login_account: 'Bitte melden Sie sich mit Ihrem Wallet bei diesem Konto an, um dessen Genehmigungen sehen zu können.',
            approval_text: 'Um den Betrag der ausgewählten Genehmigungen auf 0 zu aktualisieren, ist eine Signatur Ihres Wallets erforderlich',
            type: 'Typ',
            delete: 'Löschen',
            delete_all: 'Alles löschen',
            unselect_all: 'Alles wiederufen',
            cancel: 'Stornieren',
            remove_approval: 'Entfernen Sie es',
            removal_approval: 'Einzelgenehmigung entfernen',
            unselect_all_approvals: 'Deaktivieren Sie alle Genehmigungen',
            infinite: 'Unendlich',
            infinite_tooltip: 'Der Zuschuss ist höher als der Gesamtvorrat dieses Tokens',
            select: 'Wählen Sie Genehmigung aus',
            unselect: 'Genehmigung deaktivieren',
            update: 'Genehmigungs-Update',
            update_description: 'Geben Sie den neuen Betrag ein, um den Spender zu berücksichtigen. Hierzu ist eine Wallet-Signatur erforderlich.',
            removal_approvals: 'Entfernen Sie alle Genehmigungen',
            removal_selected_approvals: 'Alle ausgewählten Genehmigungen entfernen',
            update_failed: 'Die Genehmigung konnte nicht aktualisiert werden',
            update_success: 'Die Genehmigung von {spender} für {contract} wurde erfolgreich aktualisiert',
            spender: 'Spender',
            amount: 'Zuschuss',
        },
        nfts: {
            show_without_metadata: 'NFTs ohne Metadaten anzeigen',
            id: 'Token-ID',
            amount: 'Menge',
            name: 'Name',
            collection: 'Sammlung',
            nfts: 'NFTs',
            metadata: 'Meta',
            minter: 'Präger',
            owner: 'Eigentümer',
            contract: 'Vertrag',
            image: 'Bild',
            media: 'Medien',
            minted: 'Block geprägt',
            attributes: 'Attribut',
            ipfs: 'Von IPFS abrufen',
            consult_metadata: 'Metadaten konsultieren',
            consult_media: 'Medien konsultieren',
            consult_collection: 'Sammlung konsultieren',
        },
        holders : {
            show_system_contracts: 'Systemverträge anzeigen',
            holder: 'Halter',
            balance: 'Guthaben',
            global_supply: 'Weltweite Versorgung',
            telos_supply: 'Telos EVM Versorgung',
            updated: 'Letzte Aktualisierung\n',
        },
        transaction: {
            in: 'rein',
            out: 'raus',
            self: 'bleibt',
            load_error: 'Konnte die Transaktionen nicht laden',
            show_short: 'Kurz anzeigen',
            show_total: 'Gesamt anzeigen',
            show_wei: 'Wei anzeigen',
            value_uint256: 'Wert (uint256) : ',
            tlos_transfer: 'TLOS Übertragung',
            native_deposit: 'Einzahlung von Nativen Telos',
            native_withdraw: 'Abhebung zu Nativen Telos',
            unknown: 'Unbekannt',
            contract_deployed: 'Vertrag Bereitgestellt',
            contract_deployment: 'Vertragsbereitstellung',
            contract_interaction: 'Vertragsinteraktion',
            deposit: 'einzahlung',
            withdraw: 'abhebung',
            no_internal_trxs_found: 'Keine internen Transaktionen gefunden',
            human_readable: 'Menschenlesbar',
            no_logs_found: 'Keine Protokolle gefunden',
            verify_related_contract: 'Überprüfen Sie den zugehörigen Vertrag für jedes Protokoll, um dessen menschenlesbare Version zu sehen',
            failed_to_retrieve_contract: 'Vertrag mit Adresse { address } konnte nicht abgerufen werden',
            trx_hash: 'Transaktions-Hash',
            trx_hash_tooltip: 'Die eindeutige Kennung der Transaktion.',
            block: 'Block',
            block_tooltip: 'Die Blocknummer, in der diese Transaktion enthalten ist.',
            status: 'Status',
            status_tooltip: 'Gibt an, ob die Transaktion erfolgreich, fehlgeschlagen oder ausstehend ist',
            timestamp: 'Zeitstempel',
            timestamp_tooltip: 'Die Uhrzeit und das Datum, an dem die Transaktion bestätigt wurde.',
            trx_action: 'Transaktionsaktion',
            trx_action_tooltip: 'Die von dieser Transaktion durchgeführte Operation, wie z. B. eine Übertragung oder Vertragserfüllung.',
            from: 'Von',
            from_tooltip: 'Die Adresse, die die Transaktion initiiert.',
            to: 'An',
            to_tooltip: 'Die Empfängeradresse der Transaktion.',
            erc20_transfers: 'ERC20-Übertragungen',
            erc20_transfers_tooltip: 'Liste der ERC20-Token-Übertragungen in dieser Transaktion',
            erc721_transfers: 'ERC721-Übertragungen',
            erc721_transfers_tooltip: 'Liste der ERC721-Token-Übertragungen in dieser Transaktion',
            erc1155_transfers: 'ERC1155-Übertragungen',
            erc1155_transfers_tooltip: 'Liste der ERC1155-Token-Übertragungen in dieser Transaktion',
            tlos_transfers: 'TLOS-Übertragungen',
            tlos_transfers_tooltip: 'Liste der internen TLOS-Übertragungen in dieser Transaktion',
            value: 'Wert',
            value_tooltip: 'Der Betrag der in dieser Transaktion übertragenen Kryptowährung.',
            gas_fee: 'Transaktionsgebühr',
            gas_fee_tooltip: 'Die insgesamt für diese Transaktion gezahlte Gebühr, in TLOS und USD.',
            gas_price: 'Gaspreis',
            gas_price_tooltip: 'Der Preis pro Gaseinheit, der für die Transaktion gezahlt wurde.',
            more_details: 'Weitere Details',
            show_more_details: 'klicken, um mehr anzuzeigen',
            show_less_details: 'klicken, um weniger anzuzeigen',
            gas_limit_n_usage: 'Gaslimit & Verbrauch durch Txn',
            gas_limit_n_usage_tooltip: 'Die maximale Menge an Gas, die für die Transaktion zugelassen war, und die tatsächlich verbrauchte Gasmenge.',
            nonce: 'Nonce',
            nonce_tooltip: 'Eine sequenzielle Nummer, die jeder Transaktion von derselben Adresse zugeordnet ist, um sicherzustellen, dass Transaktionen in der richtigen Reihenfolge verarbeitet werden.',
            position_in_block: 'Position im Block',
            position_in_block_tooltip: 'Die Position dieser Transaktion im Block.',
            input: 'Eingabe',
            input_tooltip: 'Mit der Transaktion gesendete Daten, die typischerweise für Vertragsinteraktionen verwendet werden.',
        },
        inputs: {
            incorrect_address_array_length: 'Das Array sollte { size } Adressen enthalten',
            incorrect_booleans_array_length: 'In dem Array sollten { size } Boolesche Werte enthalten sein',
            incorrect_bytes_array_length: 'Das Array sollte { size } Bytes enthalten',
            odd_number_of_bytes: 'Es sollte eine gerade Anzahl von Bytezeichen vorhanden sein',
            incorrect_sigint_array_length: 'Das Array muss { size } signed Integers enthalten',
            incorrect_strings_array_length: 'Das Array sollte nur { size } Zeichenfolgen enthalten',
            incorrect_unsigint_array_length: 'Das Array muss { size } unsigned Integers enthalten',
            incorrect_values_array_length: 'Es müssen { size } Werte im Array vorhanden sein',
            incorrect_undefined_entry: 'Einige der Einträge sind nicht in einem gültigen Format',
            incorrect_values_format: 'Einige der Werte sind nicht in einem gültigen Format',
            tuple_struct_input_hint: 'Geben Sie die Tupelwerte in eckigen Klammern in der im Vertrag definierten Reihenfolge ein',
            invalid_boolean_value: 'Der eingegebene Wert ist kein boolescher Wert',
            invalid_address_array_string: 'Eingegebener Wert repräsentiert kein Array von Adressen',
            invalid_booleans_array_string: 'Der eingegebene Wert entspricht keinem Array von bool(s)',
            invalid_bytes_array_string: 'Eingegebener Wert entspricht keinem Array von Bytes dar',
            invalid_sigint_array_string: 'Eingegebener Wert entspricht keinem Array von signed Integers dar',
            invalid_strings_array_string: 'Eingegebener Wert entspricht keinem Array von Strings dar',
            invalid_unsigint_array_string: 'Eingegebener Wert entspricht keinem Array von unsigned Integers dar',
            invalid_address_length: 'Eine Adresse muss genau 40 Zeichen lang sein, exklusive Prefix "0x"',
            invalid_address_start: 'Eine Adresse muss mit 0x beginnen',
            invalid_address_characters: 'Der Eintrag enthält ungültige Zeichen',
            readonly: 'Dieses Feld ist schreibgeschützt',
            required: 'Dieses Feld ist erforderlich',
            too_large: 'Maximalwert für int{ size } ist { max }',
            too_small: 'Minimalwert für int{ size } ist { max }',
            too_large_pow2: 'Maximalwert für uint{ size } ist 2^{ size } - 1',
            too_small_pow2: 'Minimalwert für int{ size } ist -(2^{ size }) + 1',
            too_large_unsigint: 'Maximalwert für uint{ size } ist 2^{ size } - 1',
            no_negative_unsigint: 'Wert für uint{ size } darf nicht negativ sein',
            invalid_signed_integer: 'Ungültiger signed integer',
            invalid_unsigint: 'Der Eintrag muss einen gültigen unsigned integer enthalten',
            str_input_placeholder: '["Wert", ... , "Endgültiger Wert"]',
            str_input_hint: 'Doppelte Anführungszeichen in Strings müssen escaped werden (\\")',
            address_placeholder: 'Adresse beginnt mit 0x',
            address_label: '{ label } (Adresse/Konto)',
            boolean_array_label: '{ label } (bool[{ size }])',
        },
        health: {
            status: 'Status',
            checked_at: 'Geprüft am',
            task: 'Aufgabe',
            message: 'Nachricht',
            category: 'Category',
            block_height: 'Block Nummer',
            latency: 'Latenz',
            success: 'Success',
            info: 'Info',
            alert: 'Alert',
            error: 'Error',
            click_to_change_format: 'Zum Ändern des Formats anklicken',
        },
        contract_tab: {
            abi_loaded_from_interface: 'This generic ABI was loaded using IEP-165\'s supportsInterface() and may not be exhaustive. Some of the functions displayed may not be implemented. Verify the contract to be able to interact with it using its full ABI.',
            copy_abi_to_clipboard: 'ABI JSON des Smart Contracts in die Zwischenablage kopieren',
            enter_amount: 'Wählen Sie Anzahl der Dezimalstellen und geben Sie einen Betrag ein, dieser wird als Funktionsparameter als uint256 erfasst',
            result: 'Ergebnis',
            view_transaction: 'Transaktion ansehen',
            code: 'Code',
            bytecode: 'Bytecode',
            read: 'Lesen',
            write: 'Schreiben',
            amount: 'Anzahl',
            value: 'Wert',
            custom_decimals: 'Benutzerdefinierte Dezimalen',
            custom: 'Benutzerdefiniert',
            unverified_contract_source: 'Dieser Vertrag ist nicht verifiziert.',
            verified_contract_source: 'Überprüfen und Veröffentlichen Sie den Quellcode Ihres Vertrags',
            here: 'hier.',
            upload_source_files: 'um Quelldateien hochzuladen und diesen Smart Contract zu verifizieren. ',
            abi_autoloaded: 'In the meantime, you can interact with the contract using our automatically loaded preset ABI.',
            choose_abi: 'Alternatively, you can interact with the contract either using one of our preset ABIs or a custom JSON one:',
            use_erc20_abi: 'ERC20 ABI nutzen',
            use_erc721_abi: 'ERC721 ABI nutzen',
            use_erc1155_abi: 'ERC1155 ABI nutzen',
            abi_from_json: 'ABI aus JSON-Datei',
            paste_abi_json_here: 'ABI JSON hier einfügen',
            abi_json_preview: 'ABI JSON Vorschau',
            provided_abi_invalid: 'Bereitgestellte ABI ist ungültig',
            provided_json_invalid: 'Bereitgestellte JSON ist ungültig',
            read_functions: 'Lese-Funktionen',
            write_functions: 'Schreib-Funktionen',
            unverified_contract: 'Ungeprüfter Smart Contract',
            verified_contract: 'Verified contract',
            upload_abi_json: 'ABI-JSON-Datei Hochladen',
            discard_abi_json: 'Aktuellen ABI-JSON Verwerfen',
        },
        header: {
            address_copied: 'Adresse/Konto in Zwischenablage kopiert',
            address_not_found: 'Suche nach verlinkter EVM-Adresse für Telos Native Konto { search_term } fehlgeschlagen.',
            api_documentation: 'API-Dokumentation',
            blockchain: 'Blockchain',
            blocks: 'Blöcke',
            connect_wallet: 'Wallet verbinden',
            copy_address: 'Adresse/Konto kopieren',
            csv_export: 'CSV-Export',
            developers: 'Entwickler',
            disconnect_wallet_tooltip: 'Wallet trennen',
            gas: 'Gas',
            goto_address_details: 'Adresse/Konto Details öffnen',
            health_monitor: 'Health Monitor',
            home: 'Heim',
            more: 'Mehr',
            network: 'Netzwerk',
            open_language_switcher: 'open language switcher',
            search_failed: 'Suche fehlgeschlagen, bitte geben Sie einen gültigen Suchbegriff ein.',
            search_placeholder: 'Suche alles auf Telos',
            select_language: 'Sprache wählen',
            switch_to_dark_theme: 'Wechseln Sie zum dunklen Thema',
            switch_to_light_theme: 'Wechseln Sie zum Lichtthema',
            system_token_price: '{token}-Preis',
            telos_ecosystem: 'Telos-Ökosystem',
            telos_zero_explorer: 'Telos Zero Explorer',
            telos_evm_logo_alt: 'Telos EVM-Logo',
            telos_wallet: 'Wallet',
            telos_bridge: 'Bridge',
            transactions: 'Transaktionen',
            internal_transactions: 'Internal Transactions',
            verify_contract_sourcify: 'Vertrag überprüfen (Sourcify)',
            view_other_networks: 'Andere Netzwerke anzeigen',
        },
        blocks: {
            block_height: 'Blockhöhe',
            timestamp: 'Zeitstempel',
            size: 'Größe',
            gas_used: 'Verbrauchtes Gas',
            gas_limit: 'Gaslimit',
            hash: 'Hash',
            parent_hash: 'Eltern-Hash',
            nonce: 'Nonce',
            extra_data: 'Zusätzliche Daten',
            extra_data_tooltip: 'Zusätzliche Daten, die vom Miner des Blocks hinzugefügt wurden',
            block_height_tooltip: 'Auch "Blocknummer" genannt. Die Blockhöhe repräsentiert die Länge der Blockchain, die um eins nach dem Hinzufügen eines neuen Blocks zunimmt',
            timestamp_tooltip: 'Die Zeit und das Datum, zu dem der Block produziert wurde',
            transactions_tooltip: 'Die Anzahl der Transaktionen im Block. Interne Transaktionen sind Transaktionen, die als Ergebnis der Vertragsausführung auftreten und einen TLOS-Wert beinhalten',
            size_tooltip: 'die Menge an Daten im Block, bestimmt durch das Gaslimit',
            gas_used_tooltip: 'Die Menge an im Block verwendeten Gas',
            gas_limit_tooltip: 'Das Gaslimit für alle Transaktionen im Block',
            nonce_tooltip: 'Der Wert, der beim Mining verwendet wird, um einen Konsens über den Proof of Work für den Block zu erreichen',
            hash_tooltip: 'Der Hash des Blockheaders',
            parent_hash_tooltip: 'Der Hash des Elternblock',
            count_transactions: '{count} Transaktionen',
            count_transaction: '1 Transaktion',
            in_this_block: 'in diesem Block',
            transactions: 'Transaktionen',
            transactions_root: 'Transaktions-Root',
            block: 'Block',
            age: 'Alter',
        },
        footer: {
            telos: 'Telos',
            telos_homepage: 'Hauptseite',
            telos_wallet: 'Telos-Geldbörse',
            telos_bridge: 'Telos-Brücke',
            telos_zero_explorer: 'Telos Zero Explorer',
            buy_telos: 'Telos kaufen',
            stake_telos: 'Telos staken',
            telos_ecosystem: 'Telos Ökosystem',
            network_status: 'Netzwerkstatus',
            about: 'Über',
            about_us: 'Über uns',
            contactUs: 'Kontaktiere uns',
            build: 'Bauen',
            api_documentation: 'API-Dokumentation',
            telos_documentation: 'Telos Dokumentation',
            github: 'Github',
            brand_assets: 'Markenvermögen',
            back_to_top: 'Zurück nach oben',
        },
        export: {
            block_range: 'Blockbereich',
            choose_download_option: 'Wählen Sie eine Download-Option',
            column_header_action: 'Aktion',
            column_header_amount: 'Betrag',
            column_header_block_number: 'Blocknummer',
            column_header_contract_address: 'Vertragsadresse',
            column_header_date: 'Datum',
            column_header_from: 'Aus',
            column_header_nft_collection_name: 'NFT-Sammlungsname',
            column_header_nft_id: 'NFT-ID',
            column_header_timestamp: 'Unix-Zeitstempel (ms)',
            column_header_to: 'Zu',
            column_header_token_contract_address: 'Token-Vertragsadresse',
            column_header_token_name: 'Token-Name',
            column_header_token_symbol: 'Token-Symbol',
            column_header_tx_hash: 'Transaktions-Hash',
            date_range: 'Datumsbereich',
            start_date: 'Startdatum',
            end_date: 'Enddatum',
            invalid_range: 'Ungültiger Bereich',
            download_csv: 'CSV-Datei herunterladen',
            download_transactions_csv: 'Transaktionen CSV-Datei herunterladen',
            download_erc_20_transfers_csv: 'ERC-20-Übertragungen CSV-Datei herunterladen',
            download_erc_721_transfers_csv: 'ERC-721-Übertragungen CSV-Datei herunterladen',
            download_erc_1155_transfers_csv: 'ERC-1155-Übertragungen CSV-Datei herunterladen',
            end_block: 'Endblock',
            erc_1155_transfers: 'ERC-1155-Übertragungen',
            erc_20_transfers: 'ERC-20-Übertragungen',
            erc_721_transfers: 'ERC-721-Übertragungen',
            export_data: 'Daten exportieren',
            export_type: 'Export-Typ',
            limit_notice: 'Es werden nur die ersten {amount} Ergebnisse exportiert',
            notification_successful_download: 'Erfolgreicher Download',
            notification_failed_download: 'Beim Herunterladen der Daten ist ein Fehler aufgetreten',
            page_header: 'Daten herunterladen (CSV-Export)',
            reset: 'Zurücksetzen',
            start_block: 'Startblock',
            transactions: 'Transaktionen',
        },
    },
    evm_wallet: {
        send_icon_alt: 'Senden-Symbol',
        receive_icon_alt: 'Empfangen-Symbol',
        buy_icon_alt: 'Symbol zum Kaufen weiterer Token',
        token_logo_alt: 'Token-Logo',
        send: 'Senden',
        receive: 'Empfangen',
        scan_qr: 'QR-Code scannen, um Ihr Konto zu erhalten',
        buy: 'Kaufen',
        unwrap: 'Auspacken',
        wrap: 'Einpacken',
        stake: 'Staken',
        unstake: 'Entstaken',
        copy: 'Kopieren',
        link_to_send_aria: 'Link zur Senden-Seite',
        link_to_receive_aria: 'Link zur Empfangen-Seite',
        link_to_buy_aria: 'Externer Link zum Kauf von Token',
        balance_row_actions_aria: 'Aktionen der Bilanzreihe',
        no_fiat_value: 'Kein zuverlässiger Fiat-Wert gefunden',
        receiving_account: 'Empfangendes Konto',
        account_required: 'Konto ist erforderlich',
        token: 'Token',
        cancel: 'Abbrechen',
        estimated_fees: 'Geschätzte Gebühren',
        amount_available: '{amount} verfügbar',
        amount_required: 'Betrag ist erforderlich',
        view_contract: 'Vertrag anzeigen',
        invalid_form: 'Es gibt mindestens ein ungültiges Feld.',
        general_error: 'Bei der Verarbeitung Ihrer Anfrage ist ein Fehler aufgetreten',
        invalid_amount_precision: 'Sie können nur {precision} Dezimalstellen eingeben',
        sent: 'Gesendet',
        received: 'Empfangen',
        swapped: 'Getauscht',
        switch: 'Wechseln',
        failed_contract_interaction: 'Vertragsinteraktion fehlgeschlagen',
        contract_interaction: 'Vertragsinteraktion',
        contract_creation: 'Vertragserstellung',
        aria_link_to_address: 'Link zur Block-Explorer-Adressseite',
        aria_link_to_transaction: 'Link zur Block-Explorer-Transaktionsseite',
        click_to_fill_max: 'Klicken, um den maximalen Betrag einzugeben',
        amount_exceeds_available_balance: 'Guthaben zu niedrig',
        incorrect_network: 'Falsches Netzwerk erkannt! Wechseln Sie zu {networkName}, um die Transaktion abzuschließen',
        viewing_n_transactions: '{rowsPerPage} von {totalRows} Transaktionen anzeigen',
        no_transactions_found: 'Keine Transaktionen gefunden',
        add_to_metamask: 'Zu MetaMask hinzufügen',
        rejected_metamask_prompt: 'MetaMask-Aufforderung wurde abgelehnt',
        error_adding_token_to_metamask: 'Fehler beim Hinzufügen des Tokens zu MetaMask',
        inventory: 'Sammlerstücke',
    },
    global: {
        all: 'alle',
        true: 'wahr',
        false: 'falsch',
        close: 'Schließen',
        toggle_fullscreen: 'Vollbildmodus umschalten',
        name: 'Name',
        symbol: 'Symbol',
        show_table: 'Tabelle anzeigen',
        show_grid: 'Gitter anzeigen',
        records_per_page: 'Einträge pro Seite:',
        max_decimals_reached: 'Bitte verwenden Sie maximal {max} Dezimalstellen',
        erc20_token: 'ERC20-Token',
        erc721_token: 'ERC721-Token',
        sign: 'Signieren',
        hours: 'Stunde(n)',
        updated: 'Zuletzt aktualisiert',
        action: 'Aktion',
        wallet_response: 'Warten auf Antwort von Ihrem Wallet-Anbieter',
        minutes: 'Minute(n)',
        days: 'Tag(e)',
        and: 'und',
        unknown: 'Unbekannt',
        data_behind_by: 'Entschuldigung! Die angezeigten Daten sind um',
        not_synced: 'Der Indexer ist nicht mit dem neuesten Block synchronisiert',
        try_reloading: 'Sie können versuchen, die Seite neu zu laden, um das Problem zu beheben. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an ein Mitglied des Telos-Teams.',
        error: 'Fehler',
        async_error_description: 'Entschuldigung! Beim Abrufen von Ressourcen ist ein Fehler aufgetreten. Bitte stellen Sie sicher, dass Ihre Internetverbindung funktioniert, und versuchen Sie es erneut. Wenn der Fehler weiterhin besteht, kontaktieren Sie uns direkt auf unserem Telegram-Kanal.',
        language: 'Sprache',
        cancel: 'Abbrechen',
        ok: 'Ok',
        dismiss: 'Verwerfen',
        empty_block: 'Leerer Block',
        empty_block_description: 'Dieser Block hat keine Transaktionen. Versuchen Sie, zu einem anderen Block zu navigieren',
        internal_error: 'Interner Fehler',
    },
    layouts: {
        health_status: 'Gesundheitszustand',
        stake_telos: 'Stake TLOS',
        teloscan_mainnet: 'Teloscan Mainnet',
        teloscan_testnet: 'Teloscan Testnet',
    },
    notification: {
        success_title_trx: 'Erfolg',
        success_title_copied: 'Kopiert',
        success_message_trx: 'Ihre Transaktion wurde verarbeitet. Für detaillierte Informationen klicken Sie bitte auf den unten stehenden Link.',
        success_message_revoking: 'Die Zuweisung von <b>{symbol}</b> für <b>{address}</b> wurde erfolgreich widerrufen.',
        success_message_copied: 'Ihr Kontoname wurde in die Zwischenablage kopiert.',
        success_see_trx_label: 'Transaktion anzeigen',
        dismiss_label: 'Verwerfen',
        error_title: 'Oh oh!',
        error_title_disconnect: 'Keine Internetverbindung',
        error_message_disconnect: 'Es tut uns leid, es sieht so aus, als wären Sie nicht mit dem Internet verbunden. Bitte überprüfen Sie Ihre Netzwerkverbindung und versuchen Sie es erneut.',
        error_see_details_label: 'Details anzeigen',
        error_details_title: 'Fehlerdetails',
        neutral_message_sending: 'Sende <b>{quantity}</b> an <b>{address}</b>',
        neutral_message_revoking: 'Zuweisung von <b>{symbol}</b> für <b>{address}</b> erfolgreich widerrufen',
        neutral_message_wrapping: '<b>{quantity} {symbol}</b> in ERC20 umwandeln',
        neutral_message_unwrapping: '<b>{quantity} {symbol}</b> wiederherstellen',
        neutral_message_withdrawing: '<b>{quantity} {symbol}</b> abheben',
        dont_show_message_again: 'Diese Nachricht nicht mehr anzeigen',
        error_message_sending: 'Beim Senden von <b>{quantity}</b> an <b>{address}</b> ist ein Fehler aufgetreten',
        error_message_revoking: 'Beim Widerrufen der Zuweisung von <b>{symbol}</b> für <b>{address}</b> ist ein Fehler aufgetreten',
        error_message_wrapping: 'Beim Umwandeln von <b>{quantity} {symbol}</b> in ERC20 ist ein Fehler aufgetreten',
        error_message_unwrapping: 'Beim Wiederherstellen von <b>{quantity} {symbol}</b> ist ein Fehler aufgetreten',
        error_message_withdrawing: 'Beim Abheben von <b>{quantity} {symbol}</b> ist ein Fehler aufgetreten',
        error_message_custom_call: 'Beim Aufrufen von <b>{name}</b> mit <b>{params} Parametern</b> ist ein Fehler aufgetreten',
        error_message_custom_call_send: 'Beim Aufrufen von <b>{name}</b> mit <b>{params} Parametern</b> und Senden von <b>{quantity} {symbol}</b> ist ein Fehler aufgetreten',
        neutral_message_custom_call: 'Aufrufen von <b>{name}</b> mit <b>{params} Parametern</b>',
        neutral_message_custom_call_send: 'Aufrufen von <b>{name}</b> mit <b>{params} Parametern</b> und Senden von <b>{quantity} {symbol}</b>',
    },
    antelope: {
        contracts: {
            invalid_contract: 'Vertrag oder Vertrag ABI fehlt',
            contract_data_required: 'Vertragsdaten fehlen',
        },
        evm: {
            error_support_provider_request: 'Anbieter unterstützt die Anfragemethode nicht',
            error_login: 'Fehler im Anmeldevorgang',
            error_add_chain_rejected: 'Benutzer hat die Anfrage zum Hinzufügen der Kette abgelehnt',
            error_connect_rejected: 'Benutzer hat die Anfrage zur Verbindung mit der Kette abgelehnt',
            error_add_chain: 'Fehler beim Hinzufügen der Kette',
            error_switch_chain_rejected: 'Benutzer hat die Anfrage zum Wechseln der Kette abgelehnt',
            error_switch_chain: 'Fehler beim Wechseln der Kette',
            error_no_provider: 'Kein Anbieter gefunden',
            error_getting_function_interface: 'Fehler beim Versuch, die Ereignissignatur für die Funktion {prefix} zu finden',
            error_getting_event_interface: 'Fehler beim Versuch, die Ereignissignatur für das Ereignis {hex} zu finden',
            error_invalid_address: 'Ungültige Adresse',
            error_getting_contract_creation: 'Fehler beim Versuch, die Vertragserstellungsinformationen zu erhalten',
            error_no_signer: 'Kein Unterzeichner gefunden',
            error_send_transaction: 'Ein unbekannter Fehler ist beim Senden der Transaktion aufgetreten',
            error_transfer_failed: 'Ein unbekannter Fehler ist beim Übertragen von Token aufgetreten',
            error_call_exception: 'Ein Fehler ist beim Aufrufen der Smart-Contract-Funktion aufgetreten',
            error_insufficient_funds: 'Sie haben nicht genügend Mittel, um diese Transaktion abzuschließen',
            error_missing_new: 'Ein neuer Operator fehlte beim Bereitstellen des Smart Contracts',
            error_nonce_expired: 'Die für diese Transaktion verwendete Nonce ist zu niedrig',
            error_numeric_fault: 'Ein numerischer Vorgang ist fehlgeschlagen',
            error_replacement_underpriced: 'Eine neue Transaktion, die eine vorhandene ersetzt, hat nicht genügend Gaspreis',
            error_transaction_replaced: 'Diese Transaktion wurde durch eine andere Transaktion ersetzt',
            error_unpredictable_gas_limit: 'Das Gaslimit für diese Transaktion konnte nicht geschätzt werden',
            error_user_rejected: 'Sie haben die Transaktion abgelehnt',
            error_transaction_canceled: 'Sie haben die Aktion abgebrochen',
            error_wrap_not_supported_on_native: 'Wrapping wird auf der nativen Kette nicht unterstützt',
            error_unwrap_not_supported_on_native: 'Unwrapping wird auf der nativen Kette nicht unterstützt',
            error_wrap_failed: 'Ein unbekannter Fehler ist beim Wrappen von Token aufgetreten',
            error_unwrap_failed: 'Ein unbekannter Fehler ist beim Unwrappen von Token aufgetreten',
            error_withdraw_failed: 'Ein unbekannter Fehler ist beim Abheben von Token aufgetreten',
            error_fetching_token_price: 'Ein unbekannter Fehler ist beim Abrufen der Tokenpreisdaten aufgetreten',
        },
        history: {
            error_fetching_transactions: 'Unerwarteter Fehler beim Abrufen der Transaktionen. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
            error_fetching_nft_transfers: 'Unerwarteter Fehler beim Abrufen der NFT-Übertragungen. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
        },
        chain: {
            error_update_data: 'Fehler beim Aktualisieren der Daten',
            error_apy: 'Fehler beim Abrufen des APY',
            error_invalid_network: 'Ungültiges Netzwerk',
            error_no_default_authenticator: 'Kein Standard-Authentifikator gefunden',
        },
        account: {
            error_login_native: 'Beim Anmelden bei der nativen Kette ist ein Fehler aufgetreten',
            error_login_evm: 'Beim Anmelden bei der EVM-Kette ist ein Fehler aufgetreten',
            error_auto_login: 'Beim automatischen Anmelden des Benutzers ist ein Fehler aufgetreten',
        },
        utils: {
            error_parsing_transaction: 'Fehler beim Parsen der Transaktionsdaten',
            error_contract_instance: 'Fehler beim Erstellen der Vertragsinstanz',
            error_parsing_log_event: 'Fehler beim Parsen des Protokollereignisses',
        },
        balances: {
            error_at_transfer_tokens: 'Beim Übertragen von Token ist ein Fehler aufgetreten',
            error_token_contract_not_found: 'Token-Vertrag für Adresse {address} nicht gefunden',
        },
        rex: {
            error_contract_not_found: 'Vertrag für Adresse {address} nicht gefunden',
        },
        wallets: {
            error_system_token_transfer_config: 'Fehler beim Abrufen der Wagmi-Systemtoken-Transferkonfiguration',
            error_token_transfer_config: 'Fehler beim Abrufen der Wagmi-Token-Transferkonfiguration',
            error_oreid_no_chain_account: 'Die App {appName} hat kein Kettenkonto für die Kette {networkName}',
            network_switch_success: 'Netzwerk erfolgreich gewechselt',
        },
        wrap: {
            error_getting_wrapped_contract: 'Beim Abrufen des Wrapped-System-Token-Vertrags ist ein Fehler aufgetreten',
            error_wrap: 'Beim Wrappen des Systemtokens ist ein unbekannter Fehler aufgetreten',
        },
        words: {
            day: 'Tag',
            days: 'Tage',
            hour: 'Stunde',
            hours: 'Stunden',
            minute: 'Minute',
            minutes: 'Minuten',
            second: 'Sekunde',
            seconds: 'Sekunden',
            time_ago: 'Vor {time}',
        },
    },
};
