export default {
    locale: {
        current_language_name: 'Português',
    },
    pages: {
        blocklist: {
            title: 'Blocos',
            display_empty_blocks: 'Mostrar blocos vazios',
        },
        blockpage: {
            block: 'Bloco',
            overview: 'Visão Geral',
            transactions: 'Transações',
        },
        transactions: {
            contract_deployment: 'Implantação de Contrato',
            deposit_action_name: 'Depósito',
            deposit_native: 'Depósito (nativo)',
            five_hundred_k_disclaimer: 'Mostrando os últimos 500 mil registros de { total }',
            for_block: 'para o bloco',
            for: 'para',
            gas_info_label: 'Informações de Gás',
            native_deposit_tooltip: 'Depósito do Telos Nativo',
            native_withdraw_tooltip: 'Saque para o Telos Nativo',
            nonce_label: 'Nonce',
            see_more_details: 'Ver mais detalhes',
            see_tx_preview_tooltip: 'Ver prévia dos detalhes da transação',
            status_label: 'Status',
            to: 'para',
            transaction_action_label: 'Ação da Transação',
            transaction_fee_label: 'Taxa de Transação',
            transactions: 'Transações',
            transfer_for_x_tlos_from: 'Transferir { amount } TLOS de',
            transfer_tlos_action_name: 'Transferir TLOS',
            withdraw_action_name: 'Saque',
            withdraw_native: 'Saque (nativo)',
            x_gas_used_of_y_limit: '{ amount } gás usado de um limite de { limit }',
        },
        transaction: {
            page_title: 'Detalhes da Transação',
            overview: 'Visão Geral',
            logs: 'Logs',
            internal: 'Transações Internas',
            not_found: 'Transação não encontrada',
        },
        internaltrx: {
            page_title: 'Transações internas do contrato',
            for_address: 'para o endereço { address }',
        },
        home: {
            telos_evm_explorer: 'O Explorador Telos EVM',
            market_cap: 'Capitalização de mercado',
            last_finalized_block: 'Último bloco finalizado',
            total_transactions: 'Total de transações',
            latest_blocks: 'Últimos Blocos',
            view_all_blocks: 'Ver todos os blocos',
            latest_transactions: 'Últimas Transações',
            view_all_transactions: 'Ver todas as transações',
        },
        error: {
            title: 'Página não encontrada.',
            subtitle: 'Por favor, verifique novamente a URL. Se parecer correta, tente novamente mais tarde.',
        },
        issuer: 'Issuer',
        account_not_found: 'Não conseguimos encontrar esse conta',
        approvals: 'Approvals',
        supported_interfaces: 'interfaces suportadas conhecida(s)',
        explore_transactions: 'Explorar transações',
        recent_transactions: 'Transações recentes',
        rpc_endpoints: 'Endpoints RPC',
        monitor: 'Monitor',
        go_home: 'Ver Início',
        minted: 'Minted',
        holders: 'Holders',
        evm_holders: 'Todos os detentores de Telos EVM',
        total_nfts_minted: 'Total de NFTs cunhadas nessa coleção',
        telos_supply: 'Telos EVM fornecimento',
        select_sol_file: 'Selecione o arquivo de contrato .sol para carregar',
        select_json_file: 'Selecione o arquivo de objeto de entrada JSON convencional para carregar',
        paste_contract_contents: 'você deve selecionar um arquivo para carrregar ou alternar a entrada para colar conteúdos do contrato',
        contract_address: 'Endereço do contrato',
        enter_contract_address: 'Por favor digite o endereço do contrato \'0x0123...\'',
        invalid_address_format: 'formato de endereço inválido',
        compiler_version: 'Versão do compilador',
        select_compiler_version: 'selecione a versão do compilador',
        eg_contracts: 'por exemplo, \'contratos/\'',
        contract_file_directory_path: 'Caminho do diretório do(s) arquivo(s) do contrato (deixe em branco se não houver nenhum)',
        invalid_path_format: 'O caminho deve terminar com uma barra inclinada /',
        upload_file: 'carregar arquivo',
        text_input: 'entrada de texto',
        runs_value_for_optimization: 'Valor de execuções para otimização',
        constructor_arguments: 'Argumentos do construtor',
        comma_seperated_values: 'valores separados por vírgula, por exemplo, Bob,123,0x12345...',
        no_trailing_commas: 'sem vírgulas finais',
        paste_contract_code_here: 'copie e cole o código do contrato aqui...',
        enter_contract_text: 'digite ou cole o texto do contrato',
        verify_contract: 'Verificar contrato',
        reset: 'Reiniciar',
        gas_used: 'Gás usado',
        account: 'Conta',
        contract: 'Contrato',
        transfers_title: 'Transferências { type }',
        loading_transfers: 'Loading transfers',
        loading_approvals: 'Loading approvals',
        erc20_transfers: 'Transferências ERC20',
        erc20_transfers_tooltip: 'Lista de transferências de tokens ERC20 nessa transação',
        erc721_transfers: 'Transferências ERC721',
        erc721_transfers_tooltip: 'Lista de transferências de tokens ERC721 nessa transação',
        erc1155_transfers: 'Transferências ERC1155',
        erc1155_transfers_tooltip: 'Lista de transferências de tokens ERC1155 nessa transação',
        tokens: 'Tokens',
        created_at_trx: 'Criada na TRX',
        by_address: 'Por endereço',
        number_used_once: 'Número usado uma vez (nonce)',
        native_account: 'Conta nativa',
        balance: 'Saldo',
        view_source_prompt: 'Este contrato foi verificado. Você pode ver o código-fonte e os metadados na guia \'contrato\'',
        account_url: '{ domain }/account/{ account }',
        tlos_balance: '{ balance } TLOS',
        couldnt_retreive_metadata_for_address: 'Não foi possível recuperar os metadados para { address }: { message }',
        transaction_details: 'Detalhes da transação',
        transaction_not_found: 'Não encontrado: { hash }',
        general: 'Geral',
        details: 'Detalhes',
        logs: 'Logs',
        internal_txns: 'Transações internas',
        transaction_hash: 'Hash da transação',
        click_to_change_date_format: 'Clique para alterar o formato da data',
        block_number: 'Número do bloco',
        from: 'De',
        to: 'Para',
        date: 'Data',
        success: 'Sucesso',
        failure: 'Falha',
        status: 'Status',
        balance_gwei: '{ amount } GWEI',
        balance_tlos: '{ amount } TLOS',
        error_message: 'Mensagem de erro',
        contract_function: 'Função do contrato',
        function_parameters: 'Parâmetros da função',
        deployed_contract: 'Contrato implantado',
        click_to_show_in_wei: 'Clique para mostrar em wei',
        gas_price_charged: 'Preço do gás cobrado',
        gas_fee: 'Taxa de gás',
        gas_limit: 'Limite de gás',
        nonce: 'Nonce',
        input: 'Entrada',
        output: 'Saída',
        value: 'Valor',
        count: 'Cuenta',
        overview: 'visão geral',
        more_info: 'mais informações',
        transaction_sent: 'txn enviado',
        first: 'primeiro',
        last: 'último',
        contract_creator: 'criador do contrato',
    },
    components: {
        gas_limit_tooltip: 'A quantidade máxima de gás que pode ser gasta nesta transação',
        gas_used_tooltip: 'A quantidade de gás que foi gasta nesta transação',
        known_tokens: 'Known tokens',
        other_tokens: 'Unknown tokens',
        no_balances_found: 'Nenhum saldo de token erc20 foi encontrado para este endereço',
        internal_txns: 'Transações internas',
        n_internal_txns: '{ amount } transações internas',
        none: 'Nenhum',
        verify_prompt: 'Este contrato ainda não foi verificado. Você gostaria de carregar o(s) contrato(s) e os metadados para verificar o código-fonte agora?',
        verify_contract: 'Verificar contrato',
        search_evm_address_failed: 'A busca pelo endereço EVM vinculado à conta nativa { accountName } falhou. Você pode criar uma em wallet.telos.net',
        unknown_web3_login_type: 'Tipo de login web3 desconhecido: { provider }',
        unknown_evm_login_provider: 'Provedor de login EVM desconhecido: { provider }',
        unknown_native_login_provider: 'Provedor de login nativo desconhecido: { provider }',
        connect_wallet: 'Conectar carteira',
        view_address: 'Ver endereço',
        disconnect: 'Desconectar',
        disable_wallet_extensions: 'Desative as extensões de carteira do navegador ou defina o Brave Wallet como padrão nas configurações para utilizá-lo.',
        enable_wallet_extensions: 'Habilite a extensão MetaMask e defina `preferir extensões` como padrão nas configurações da carteira do navegador para utilizá-la.',
        evm_wallets: 'Carteiras EVM',
        advanced: 'Avançado',
        continue_on_metamask: 'Continuar no Metamask',
        text1_native_wallets: 'Carteiras nativas para',
        text2_advanced_users: 'usuários avançados',
        text3_or_to_recover_assets: 'ou para recuperar ativos enviados a um endereço nativo vinculado',
        copy_to_clipboard: 'Copiar { text } para a Área de Transferência',
        latest_block: 'Último bloqueio',
        price_sources: 'Data obtained from Coingecko, CoinMarketCap or if unavailable from decentralized exchanges on Telos EVM',
        marketcap_sources: 'Data obtained from Coingecko or CoinMarketCap',
        usd_marketcap: 'USD Marketcap',
        usd_price: 'Preço da USD',
        usd_value: 'USD Value',
        tlos_price: 'Preço da TLOS',
        gas_price: 'Preço do gás',
        gas_price_tlos: '(Gas Price * Gas Used) in TLOS',
        gas_price_gwei: 'Gas Price in gwei',
        click_to_expand: 'Clique para expandir',
        click_to_fold: 'Clique para dobrar',
        expand_all: 'Expandir tudo',
        collapse_all: 'Dobrar tudo',
        search_evm_failed: 'A busca pelo endereço EVM vinculado à conta nativa { search_term } falhou.',
        search_failed: 'A busca falhou, digite um termo de pesquisa válido.',
        add_to_metamask: 'Adicionar { symbol } ao MetaMask',
        tx_hash: 'Txn Hash',
        direction: 'Direção',
        block: 'Bloco',
        date: 'Date Time (UTC)',
        age: 'Age',
        method: 'Método',
        to_interacted_with: 'Para',
        value: 'Valor',
        txn_fee: 'Txn Fee',
        txn_failed: 'Txn Falha',
        token: 'Token',
        unknown_precision: 'Precisão desconhecida',
        click_to_change_format: 'Clique para mudar o formato',
        balance: 'Saldo',
        error_fetching_balance: 'erro ao recuperar o saldo',
        launch_metamask_dialog_to_add: 'Iniciar interação com o MetaMask para adicionar { symbol }',
        search_hints: 'Transação, Endereço, Bloco',
        no_provider_found: 'Mais de um provedor está ativo, desative provedores adicionais ou o provedor de carteira EVM atual não é suportado.',
        copied: 'Copiado',
        copy_to_clipboard_failed: 'Falha ao copiar para a área de transferência',
        gwei: 'Gwei',
        failed_to_fetch_transactions: 'Failed to fetch transactions.',
        failed_to_parse_transaction: 'Falha ao analisar os dados da transação, o erro foi: { message }',
        executed_based_on_decoded_data: 'Name of the function executed based. For unidentified functions, method ID is displayed instead.',
        unsupported_token_type: 'Tipo de token não suportado: { tokenType }',
        token_id: 'Id #{ tokenId }',
        status: 'Status',
        nonce: 'Nonce',
        from: 'De',
        to: 'Para',
        item: 'Item',
        amount: 'Quantidade',
        download_image: 'Baixar imagem',
        confirm_download_image: 'Confirme que deseja baixar esta imagem',
        confirm: 'Confirmar',
        click_to_toggle_media_size: 'Clique para alternar o tamanho da mídia',
        toggle_expand: 'Alternar ajuste à tela ou tamanho original',
        close: 'Fechar',
        input_viewer: {
            name: 'Nome',
            type: 'Tipo',
            data: 'Dados',
            default_view: 'Vista padrão',
            original_view: 'Vista original',
            decoded_view: 'Vista decodificada',
            copy_button_desc: 'tabela de parâmetros',
        },
        approvals: {
            token_id: 'Token ID',
            approved: 'Aprovado',
            approvals_granted_title: 'Aprovações ERC:',
            login_account: 'Faça login nesta conta usando sua carteira para poder ver suas aprovações.',
            approval_text: 'É necessária uma assinatura de sua carteira para atualizar o valor das aprovações selecionadas para 0.',
            type: 'Tipo',
            delete: 'Excluir',
            delete_all: 'Excluir todos',
            unselect_all: 'Desmarque todos',
            cancel: 'Cancelar',
            remove_approval: 'Remova',
            removal_approval: 'Remover aprovação única',
            unselect_all_approvals: 'Desmarque todas as aprovações',
            infinite: 'Infinita',
            infinite_tooltip: 'A permissão é maior que o fornecimento total deste token',
            select: 'Selecione a aprovação',
            unselect: 'Desmarcar aprovação',
            update: 'Atualização de aprovação',
            update_description: 'Insira o novo valor para permitir o gastador. Isso exigirá uma assinatura de carteira.',
            removal_approvals: 'Remover todas as aprovações',
            removal_selected_approvals: 'Remover todas as aprovações selecionadas',
            update_failed: 'Falha ao atualizar a aprovação',
            update_success: 'A aprovação de {spender} para o {contract} foi atualizada com sucesso',
            spender: 'Gastador',
            amount: 'Mesada',
        },
        nfts: {
            show_without_metadata: 'Mostrar NFTs sem metadados',
            id: 'ID do Token',
            amount: 'Quantidade',
            name: 'Nome',
            collection: 'Coleção',
            nfts: 'NFTs',
            metadata: 'Meta',
            minter: 'Cunhador',
            owner: 'Proprietário',
            contract: 'Contrato',
            image: 'Imagem',
            media: 'Mídia',
            minted: 'Bloco cunhado',
            attributes: 'atributo',
            ipfs: 'Obter do IPFS',
            consult_metadata: 'Consultar metadados',
            consult_media: 'Consultar mídia',
            consult_collection: 'Consultar coleção',
        },
        holders : {
            show_system_contracts: 'Mostrar contratos do sistema',
            holder: 'Suporte',
            balance: 'Saldo',
            global_supply: 'Fornecimento global',
            telos_supply: 'Fornecimento Telos EVM',
            updated: 'Ultima atualização',
        },
        transaction: {
            in: 'entra',
            out: 'sai',
            self: 'fica',
            load_error: 'Não foi possível carregar as transações',
            show_short: 'Mostrar curto',
            show_total: 'Mostrar total',
            show_wei: 'Mostrar wei',
            value_uint256: 'Valor (uint256) : ',
            tlos_transfer: 'Transferência TLOS',
            native_deposit: 'Depósito do Telos Nativo',
            native_withdraw: 'Retirada para Telos Nativo',
            unknown: 'Desconhecido',
            contract_deployed: 'Contrato Implantado',
            contract_deployment: 'Implantação de Contrato',
            contract_interaction: 'Interação de Contrato',
            deposit: 'depósito',
            withdraw: 'retirada',
            no_internal_trxs_found: 'Nenhuma transação interna encontrada',
            human_readable: 'Legível por Humanos',
            no_logs_found: 'Nenhum registro encontrado',
            verify_related_contract: 'Verifique o contrato relacionado para cada registro para ver sua versão legível por humanos',
            failed_to_retrieve_contract: 'Falha ao recuperar o contrato com o endereço { address }',
            trx_hash: 'Hash da Transação',
            trx_hash_tooltip: 'O identificador único da transação.',
            block: 'Bloco',
            block_tooltip: 'O número do bloco em que esta transação foi incluída.',
            status: 'Status',
            status_tooltip: 'Indica se a transação foi bem-sucedida, falhou ou está pendente',
            timestamp: 'Carimbo de data/hora',
            timestamp_tooltip: 'A hora e a data em que a transação foi confirmada.',
            trx_action: 'Ação da Transação',
            trx_action_tooltip: 'A operação realizada por esta transação, como uma transferência ou execução de contrato.',
            from: 'De',
            from_tooltip: 'O endereço que inicia a transação.',
            to: 'Para',
            to_tooltip: 'O endereço de destino da transação.',
            erc20_transfers: 'Transferências ERC20',
            erc20_transfers_tooltip: 'Lista de transferências de tokens ERC20 nesta transação',
            erc721_transfers: 'Transferências ERC721',
            erc721_transfers_tooltip: 'Lista de transferências de tokens ERC721 nesta transação',
            erc1155_transfers: 'Transferências ERC1155',
            erc1155_transfers_tooltip: 'Lista de transferências de tokens ERC1155 nesta transação',
            tlos_transfers: 'Transferências TLOS',
            tlos_transfers_tooltip: 'Lista de transferências internas de TLOS nesta transação',
            value: 'Valor',
            value_tooltip: 'A quantidade de criptomoeda transferida nesta transação.',
            gas_fee: 'Taxa de Transação',
            gas_fee_tooltip: 'A taxa total paga por esta transação, em TLOS e USD.',
            gas_price: 'Preço do Gás',
            gas_price_tooltip: 'O preço por unidade de gás pago pela transação.',
            more_details: 'Mais Detalhes',
            show_more_details: 'clique para mostrar mais',
            show_less_details: 'clique para mostrar menos',
            gas_limit_n_usage: 'Limite de Gás e Uso por Txn',
            gas_limit_n_usage_tooltip: 'A quantidade máxima de gás permitida para a transação e a quantidade real de gás usada.',
            nonce: 'Nonce',
            nonce_tooltip: 'Um número sequencial associado a cada transação feita pelo mesmo endereço, garantindo que as transações sejam processadas em ordem.',
            position_in_block: 'Posição no Bloco',
            position_in_block_tooltip: 'A posição desta transação no bloco.',
            input: 'Entrada',
            input_tooltip: 'Dados enviados junto com a transação, tipicamente usados para interações com contratos.',
        },
        inputs: {
            incorrect_address_array_length: 'Deve haver { size } endereços na matriz',
            incorrect_booleans_array_length: 'Deve haver { size } booleanos na matriz',
            incorrect_bytes_array_length: 'Deve haver { size } bytes na matriz',
            odd_number_of_bytes: 'Deve haver um número par de caracteres de byte',
            incorrect_sigint_array_length: 'Deve haver { size } inteiros com sinal na matriz',
            incorrect_strings_array_length: 'Deve haver { size } strings (sequência de caracteres) na matriz',
            incorrect_unsigint_array_length: 'Deve haver { size } inteiros sem sinal na matriz',
            incorrect_values_array_length: 'Devem haver { size } valores na matriz',
            incorrect_undefined_entry: 'Algumas das entradas não estão em um formato válido',
            incorrect_values_format: 'Alguns dos valores não estão em um formato válido',
            tuple_struct_input_hint: 'Insira os valores da tupla entre colchetes na ordem definida no contrato',
            invalid_boolean_value: 'O valor inserido não é um booleano',
            invalid_address_array_string: 'O valor inserido não representa uma matriz de endereços',
            invalid_booleans_array_string: 'O valor inserido não representa uma matriz de booleanos',
            invalid_bytes_array_string: 'O valor inserido não representa uma matriz de bytes',
            invalid_sigint_array_string: 'O valor inserido não representa uma matriz de inteiros com sinal',
            invalid_strings_array_string: 'O valor inserido não representa uma matriz de strings (sequência de caracteres)',
            invalid_unsigint_array_string: 'O valor inserido não representa uma matriz de inteiros sem sinal',
            invalid_address_length: 'Um endereço deve ter exatamente 40 caracteres, sem incluir "0x"',
            invalid_address_start: 'Um endereço deve começar com 0x',
            invalid_address_characters: 'A entrada contém caracteres inválidos',
            readonly: 'Este campo é só de leitura',
            required: 'Este campo é obrigatório',
            too_large: 'O valor máximo para int{ size } é { max }',
            too_small: 'O valor mínimo para int{ size } é { max }',
            too_large_pow2: 'O valor máximo para uint{ size } é 2^{ size } - 1',
            too_small_pow2: 'O valor mínimo para int{ size } é -(2^{ size }) + 1',
            too_large_unsigint: 'O valor máximo para uint{ size } é 2^{ size } - 1',
            no_negative_unsigint: 'O valor para uint{ size } não deve ser negativo',
            invalid_signed_integer: 'Inteiro com sinal inválido',
            invalid_unsigint: 'A entrada deve ser um inteiro não sem sinal válido',
            str_input_placeholder: '["algum valor", ... , "valor final"]',
            str_input_hint: 'Aspas duplas em strings (sequência de caracteres) devem ser evitadas(\\")',
            address_placeholder: 'Endereço que começa com 0x',
            address_label: '{ label } (endereço)',
            boolean_array_label: '{ label } (bool[{ size }])',
        },
        health: {
            status: 'Estado',
            checked_at: 'Verificado em',
            task: 'Tarefa',
            message: 'Mensagem',
            category: 'Category',
            block_height: 'Altura do bloco',
            latency: 'Latência',
            success: 'Success',
            info: 'Info',
            alert: 'Alert',
            error: 'Error',
            click_to_change_format: 'Clique para alterar o formato',
        },
        contract_tab: {
            abi_loaded_from_interface: 'Essa ABI genérica foi carregada usando a função supportsInterface() do IEP-165 e pode não ser exaustiva. Algumas das funções exibidas podem não estar implementadas. Verifique o contrato para poder interagir com ele usando sua ABI completa.',
            copy_abi_to_clipboard: 'Copiar ABI JSON do contrato para a área de transferência',
            enter_amount: 'Selecione o número de decimais e insira um valor, este será inserido para você no parâmetro da função como uint256',
            result: 'Resultado',
            view_transaction: 'Ver transação',
            code: 'Código',
            bytecode: 'Código de bytes',
            read: 'Ler',
            write: 'Escrever',
            amount: 'Montante',
            value: 'Valor',
            custom_decimals: 'Decimais personalizados',
            custom: 'Personalizado',
            unverified_contract_source: 'Este contrato não está verificado.',
            verified_contract_source: 'Verifique e Publique o código-fonte do seu contrato',
            here: 'aqui.',
            upload_source_files: 'para fazer upload dos arquivos-fonte, o que também verificará este contrato.',
            abi_autoloaded: 'Enquanto isso, você pode interagir com o contrato usando nossa ABI predefinida carregada automaticamente.',
            choose_abi: 'Como alternativa, você pode interagir com o contrato usando uma de nossas ABIs predefinidas ou uma JSON personalizada:',
            use_erc20_abi: 'Usar ABI ERC20',
            use_erc721_abi: 'Usar ABI ERC721',
            use_erc1155_abi: 'Usar ABI ERC1155',
            abi_from_json: 'ABI de JSON',
            paste_abi_json_here: 'Cole o ABI JSON aqui',
            abi_json_preview: 'Prévia do ABI JSON',
            provided_abi_invalid: 'O ABI fornecido é inválido',
            provided_json_invalid: 'O JSON fornecido é inválido',
            read_functions: 'Funções de leitura',
            write_functions: 'Funções de escrita',
            unverified_contract: 'Contrato não verificado',
            verified_contract: 'Contrato verificado',
            upload_abi_json: 'Fazer upload do arquivo JSON de ABI',
            discard_abi_json: 'Descartar o JSON de ABI atual',
        },
        header: {
            address_copied: 'Endereço copiado para a área de transferência',
            address_not_found: 'A busca pelo endereço EVM vinculado à conta nativa { account } falhou.',
            api_documentation: 'Documentação da API',
            blockchain: 'Blockchain',
            blocks: 'Blocos',
            connect_wallet: 'Conectar Carteira',
            copy_address: 'Copiar endereço',
            csv_export: 'Exportar CSV',
            developers: 'Desenvolvedores',
            disconnect_wallet_tooltip: 'Desconectar carteir',
            gas: 'Gas',
            goto_address_details: 'Ir para detalhes do endereço',
            health_monitor: 'Monitoramento de saúde',
            home: 'Início',
            more: 'Mais',
            network: 'Rede',
            open_language_switcher: 'Abrir o seletor de idioma',
            search_failed: 'A busca falhou, insira um termo de pesquisa válido.',
            search_placeholder: 'Pesquise qualquer coisa no Telos',
            select_language: 'Selecione o idioma',
            switch_to_dark_theme: 'Mudar para tema escuro',
            switch_to_light_theme: 'Mudar para tema claro',
            system_token_price: 'Preço do {token}',
            telos_ecosystem: 'Ecossistema Telos',
            telos_zero_explorer: 'Explorador Telos Zero',
            telos_evm_logo_alt: 'Logo do Telos EVM',
            telos_wallet: 'Wallet',
            telos_bridge: 'Bridge',
            transactions: 'Transações',
            internal_transactions: 'Transações Internas',
            verify_contract_sourcify: 'Verificar Contrato (Sourcify)',
            view_other_networks: 'Visualizar outras redes',
        },
        blocks: {
            block_height: 'Altura do Bloco',
            timestamp: 'Timestamp',
            size: 'Tamanho',
            gas_used: 'Gás Usado',
            gas_limit: 'Limite de Gás',
            hash: 'Hash',
            parent_hash: 'Hash Pai',
            nonce: 'Nonce',
            extra_data: 'Dados Extras',
            extra_data_tooltip: 'Dados extras incluídos no bloco',
            block_height_tooltip: 'Também chamado de "Número do Bloco". A altura do bloco representa o comprimento da blockchain, aumentando um após a adição de um novo bloco',
            timestamp_tooltip: 'O momento e data em que o bloco foi produzido',
            transactions_tooltip: 'O número de transações no bloco. Transações Internas são transações que ocorrem como resultado da execução de contrato e incluem um valor TLOS',
            size_tooltip: 'a quantidade de dados no bloco, determinada pelo limite de gás',
            gas_used_tooltip: 'A quantidade de gás usada no bloco',
            gas_limit_tooltip: 'O limite de gás para todas as transações no bloco',
            nonce_tooltip: 'O valor usado durante a mineração para alcançar consenso sobre a Prova de Trabalho para o bloco',
            hash_tooltip: 'O hash do cabeçalho do bloco',
            parent_hash_tooltip: 'O hash do bloco pai',
            count_transactions: '{count} transações',
            count_transaction: '1 transação',
            in_this_block: 'neste bloco',
            transactions: 'Transações',
            transactions_root: 'Raiz das Transações',
            block: 'Bloco',
            age: 'Idade',
        },
        footer: {
            telos: 'Telos',
            telos_homepage: 'Página Principal',
            telos_wallet: 'Carteira Telos',
            telos_bridge: 'Ponte Telos',
            telos_zero_explorer: 'Explorador Telos Zero',
            buy_telos: 'Comprar Telos',
            stake_telos: 'Apostar Telos',
            telos_ecosystem: 'Ecossistema Telos',
            network_status: 'Status da Rede',
            about: 'Sobre',
            about_us: 'Sobre nós',
            contactUs: 'Fale conosco',
            build: 'Construir',
            api_documentation: 'Documentação da API',
            telos_documentation: 'Documentação do Telos',
            github: 'Github',
            brand_assets: 'Ativos da Marca',
            back_to_top: 'Voltar ao topo',
        },
        export: {
            block_range: 'Intervalo de blocos',
            choose_download_option: 'Escolha uma opção de download',
            column_header_action: 'Ação',
            column_header_amount: 'Montante',
            column_header_block_number: 'Hash de transação',
            column_header_contract_address: 'Endereço do Contrato',
            column_header_date: 'Data',
            column_header_from: 'De',
            column_header_nft_collection_name: 'Nome da coleção NFT',
            column_header_nft_id: 'ID do NFT',
            column_header_timestamp: 'Carimbo de data e hora Unix (ms)',
            column_header_to: 'Para',
            column_header_token_contract_address: 'Endereço do Contrato do Token',
            column_header_token_name: 'Nome do Token',
            column_header_token_symbol: 'Símbolo do Token',
            column_header_tx_hash: 'Transaction Hash',
            date_range: 'Intervalo de datas',
            start_date: 'Data de início',
            end_date: 'Data final',
            invalid_range: 'Intervalo inválido',
            download_csv: 'Baixar CSV',
            download_transactions_csv: 'Baixar CSV de transações',
            download_erc_20_transfers_csv: 'Baixar CSV de transferências ERC-20',
            download_erc_721_transfers_csv: 'Baixar CSV de transferências ERC-721',
            download_erc_1155_transfers_csv: 'Baixar CSV de transferências ERC-1155',
            end_block: 'Bloco final',
            erc_1155_transfers: 'Transferências ERC-1155',
            erc_20_transfers: 'Transferências ERC-20',
            erc_721_transfers: 'Transferências ERC-721',
            export_data: 'Exportar dados',
            export_type: 'Tipo de exportação',
            limit_notice: 'Apenas os primeiros {amount} resultados serão exportados',
            notification_failed_download: 'Ocorreu um erro ao baixar dados',
            notification_successful_download: 'Download bem-sucedido',
            page_header: 'Baixar dados (exportação CSV)',
            reset: 'Reiniciar',
            start_block: 'Bloco inicial',
            transactions: 'Transações',
        },
    },
    evm_wallet: {
        send_icon_alt: 'Ícone de envio',
        receive_icon_alt: 'Ícone de recebimento',
        buy_icon_alt: 'Ícone de compra de mais tokens',
        token_logo_alt: 'Logo do token',
        send: 'Enviar',
        receive: 'Receber',
        scan_qr: 'Escaneie o código QR para obter sua conta',
        buy: 'Comprar',
        unwrap: 'Desembrulhar',
        wrap: 'Embrulhar',
        stake: 'Fazer Stake',
        unstake: 'Retirar Stake',
        copy: 'Copiar',
        link_to_send_aria: 'Link para a página de envio',
        link_to_receive_aria: 'Link para a página de recebimento',
        link_to_buy_aria: 'Link externo para comprar tokens',
        balance_row_actions_aria: 'Ações da linha de saldo',
        no_fiat_value: 'Nenhum valor fiduciário confiável encontrado',
        receiving_account: 'Conta de Recebimento',
        account_required: 'Conta é necessária',
        token: 'Token',
        cancel: 'Cancelar',
        estimated_fees: 'Taxas Estimadas',
        amount_available: '{amount} disponível',
        amount_required: 'Valor é necessário',
        view_contract: 'Ver Contrato',
        invalid_form: 'Há pelo menos um campo inválido.',
        general_error: 'Houve um erro ao processar sua solicitação',
        invalid_amount_precision: 'Você só pode inserir {precision} casas decimais',
        sent: 'Enviado',
        received: 'Recebido',
        swapped: 'Trocado',
        switch: 'Trocar',
        failed_contract_interaction: 'Interação com o contrato falhou',
        contract_interaction: 'Interação com o contrato',
        contract_creation: 'Criação de contrato',
        aria_link_to_address: 'Link para a página de endereço do explorador de blocos',
        aria_link_to_transaction: 'Link para a página de transação do explorador de blocos',
        click_to_fill_max: 'Clique para preencher o valor máximo',
        amount_exceeds_available_balance: 'Saldo insuficiente',
        incorrect_network: 'Rede incorreta detectada! Troque para {networkName} para completar a transação',
        viewing_n_transactions: 'Visualizando {rowsPerPage} de {totalRows} transações',
        no_transactions_found: 'Nenhuma transação encontrada',
        add_to_metamask: 'Adicionar ao MetaMask',
        rejected_metamask_prompt: 'O prompt do MetaMask foi rejeitado',
        error_adding_token_to_metamask: 'Erro ao adicionar token ao MetaMask',
        inventory: 'Colecionáveis',
    },
    global: {
        all: 'todo',
        true: 'verdadeiro',
        false: 'falso',
        close: 'Fechar',
        toggle_fullscreen: 'Alternar para o modo de tela cheia',
        name: 'Nome',
        symbol: 'Símbolo',
        show_table: 'Mostrar tabela',
        show_grid: 'Mostrar grade',
        records_per_page: 'Registros por página:',
        max_decimals_reached: 'Por favor, use no máximo {max} casas decimais',
        erc20_token: 'Token ERC20',
        erc721_token: 'Token ERC721',
        sign: 'Assinar',
        hours: 'hora(s)',
        updated: 'Última atualização',
        action: 'Ação',
        wallet_response: 'Aguardando resposta do seu provedor de carteira',
        minutes: 'minuto(s)',
        days: 'dia(s)',
        and: 'e',
        unknown: 'Desconhecido',
        data_behind_by: 'Desculpe! Os dados exibidos estão atrasados por',
        not_synced: 'O indexador não está sincronizado com o último bloco',
        try_reloading: 'Você pode tentar recarregar a página para corrigir isso. Se o problema persistir, entre em contato com um membro da equipe Telos.',
        error: 'Erro',
        async_error_description: 'Desculpe! Ocorreu um erro ao tentar solicitar recursos. Por favor, certifique-se de que sua conexão com a internet esteja funcionando e tente novamente. Se o erro persistir, entre em contato diretamente no nosso canal do Telegram.',
        language: 'Idioma',
        cancel: 'Cancelar',
        ok: 'Ok',
        dismiss: 'Dispensar',
        empty_block: 'Bloco vazio',
        empty_block_description: 'Este bloco não tem transações. Tente navegar para um bloco diferente',
        internal_error: 'Erro Interno',
    },
    layouts: {
        health_status: 'Estado de saúde',
        stake_telos: 'Stake TLOS',
        teloscan_mainnet: 'Teloscan Mainnet',
        teloscan_testnet: 'Teloscan Testnet',
    },
    notification: {
        success_title_trx: 'Sucesso',
        success_title_copied: 'Copiado',
        success_message_trx: 'Sua transação foi processada. Para obter informações detalhadas, clique no link abaixo.',
        success_message_revoking: 'A revogação da alocação de <b>{symbol}</b> para <b>{address}</b> foi realizada com sucesso.',
        success_message_copied: 'O nome da sua conta foi copiado para a área de transferência.',
        success_see_trx_label: 'Ver Transação',
        dismiss_label: 'Descartar',
        error_title: 'Ops!',
        error_title_disconnect: 'Sem conexão com a Internet',
        error_message_disconnect: 'Desculpe, parece que você não está conectado à Internet. Por favor, verifique sua conexão de rede e tente novamente.',
        error_see_details_label: 'Ver Detalhes',
        error_details_title: 'Detalhes do Erro',
        neutral_message_sending: 'Enviando <b>{quantity}</b> para <b>{address}</b>',
        neutral_message_revoking: 'Revogando alocação de <b>{symbol}</b> para <b>{address}</b>',
        neutral_message_wrapping: 'Convertendo <b>{quantity} {symbol}</b> para ERC20',
        neutral_message_unwrapping: 'Restaurando <b>{quantity} {symbol}</b>',
        neutral_message_withdrawing: 'Retirando <b>{quantity} {symbol}</b>',
        dont_show_message_again: 'Não mostrar esta mensagem novamente',
        error_message_sending: 'Ocorreu um erro ao enviar <b>{quantity}</b> para <b>{address}</b>',
        error_message_revoking: 'Ocorreu um erro ao revogar a alocação de <b>{symbol}</b> para <b>{address}</b>',
        error_message_wrapping: 'Ocorreu um erro ao converter <b>{quantity} {symbol}</b> para ERC20',
        error_message_unwrapping: 'Ocorreu um erro ao restaurar <b>{quantity} {symbol}</b>',
        error_message_withdrawing: 'Ocorreu um erro ao retirar <b>{quantity} {symbol}</b>',
        error_message_custom_call: 'Ocorreu um erro ao chamar <b>{name}</b> com <b>{params} parâmetros</b>',
        error_message_custom_call_send: 'Ocorreu um erro ao chamar <b>{name}</b> com <b>{params} parâmetros</b> e enviar <b>{quantity} {symbol}</b>',
        neutral_message_custom_call: 'Chamando <b>{name}</b> com <b>{params} parâmetros</b>',
        neutral_message_custom_call_send: 'Chamando <b>{name}</b> com <b>{params} parâmetros</b> e enviando <b>{quantity} {symbol}</b>',
    },
    antelope: {
        contracts: {
            invalid_contract: 'Contrato ou ABI do contrato ausente',
            contract_data_required: 'Dados do contrato ausentes',
        },
        evm: {
            error_support_provider_request: 'O provedor não suporta o método de solicitação',
            error_login: 'Erro no processo de login',
            error_add_chain_rejected: 'O usuário rejeitou a solicitação para adicionar a cadeia',
            error_connect_rejected: 'O usuário rejeitou a solicitação para conectar-se à cadeia',
            error_add_chain: 'Erro ao adicionar a cadeia',
            error_switch_chain_rejected: 'O usuário rejeitou a solicitação para trocar a cadeia',
            error_switch_chain: 'Erro ao trocar a cadeia',
            error_no_provider: 'Nenhum provedor encontrado',
            error_getting_function_interface: 'Erro ao tentar encontrar a assinatura do evento para a função {prefix}',
            error_getting_event_interface: 'Erro ao tentar encontrar a assinatura do evento para o evento {hex}',
            error_invalid_address: 'Endereço inválido',
            error_getting_contract_creation: 'Erro ao tentar obter informações de criação do contrato',
            error_no_signer: 'Nenhum signatário encontrado',
            error_send_transaction: 'Ocorreu um erro desconhecido ao enviar a transação',
            error_transfer_failed: 'Ocorreu um erro desconhecido ao transferir tokens',
            error_call_exception: 'Ocorreu um erro ao chamar a função do contrato inteligente',
            error_insufficient_funds: 'Você não tem fundos suficientes para completar esta transação',
            error_missing_new: 'Faltou um novo operador ao implantar o contrato inteligente',
            error_nonce_expired: 'O nonce usado para esta transação é muito baixo',
            error_numeric_fault: 'Uma operação numérica falhou',
            error_replacement_underpriced: 'Uma nova transação que substitui uma existente não tem preço de gás suficiente',
            error_transaction_replaced: 'Esta transação foi substituída por outra',
            error_unpredictable_gas_limit: 'O limite de gás para esta transação não pôde ser estimado',
            error_user_rejected: 'Você rejeitou a transação',
            error_transaction_canceled: 'Você cancelou a ação',
            error_wrap_not_supported_on_native: 'Wrap não é suportado na cadeia nativa',
            error_unwrap_not_supported_on_native: 'Unwrap não é suportado na cadeia nativa',
            error_wrap_failed: 'Ocorreu um erro desconhecido ao envolver tokens',
            error_unwrap_failed: 'Ocorreu um erro desconhecido ao desenrolar tokens',
            error_withdraw_failed: 'Ocorreu um erro desconhecido ao sacar tokens',
            error_fetching_token_price: 'Ocorreu um erro desconhecido ao obter dados de preço do token',
        },
        history: {
            error_fetching_transactions: 'Erro inesperado ao buscar transações. Atualize a página para tentar novamente.',
            error_fetching_nft_transfers: 'Erro inesperado ao buscar transferências de NFT. Atualize a página para tentar novamente.',
        },
        chain: {
            error_update_data: 'Erro ao atualizar os dados',
            error_apy: 'Erro ao obter o APY',
            error_invalid_network: 'Rede inválida',
            error_no_default_authenticator: 'Nenhum autenticador padrão encontrado',
        },
        account: {
            error_login_native: 'Ocorreu um erro ao tentar fazer login na cadeia nativa',
            error_login_evm: 'Ocorreu um erro ao tentar fazer login na cadeia EVM',
            error_auto_login: 'Ocorreu um erro ao tentar fazer login automático do usuário',
        },
        utils: {
            error_parsing_transaction: 'Falha ao analisar os dados da transação',
            error_contract_instance: 'Falha ao criar instância do contrato',
            error_parsing_log_event: 'Falha ao analisar o evento de log',
        },
        balances: {
            error_at_transfer_tokens: 'Ocorreu um erro ao tentar transferir tokens',
            error_token_contract_not_found: 'Contrato de token não encontrado para o endereço {address}',
        },
        rex: {
            error_contract_not_found: 'Contrato não encontrado para o endereço {address}',
        },
        wallets: {
            error_system_token_transfer_config: 'Erro ao obter a configuração de transferência de tokens do sistema Wagmi',
            error_token_transfer_config: 'Erro ao obter a configuração de transferência de tokens Wagmi',
            error_oreid_no_chain_account: 'O aplicativo {appName} não possui uma conta de cadeia para a cadeia {networkName}',
            network_switch_success: 'Rede trocada com sucesso',
        },
        wrap: {
            error_getting_wrapped_contract: 'Ocorreu um erro ao obter o contrato de token do sistema envolvido',
            error_wrap: 'Ocorreu um erro desconhecido ao envolver o token do sistema',
        },
        words: {
            day: 'dia',
            days: 'dias',
            hour: 'hora',
            hours: 'horas',
            minute: 'minuto',
            minutes: 'minutos',
            second: 'segundo',
            seconds: 'segundos',
            time_ago: 'Há {time}',
        },
    },
};
